/*
 * @Descripttion:
 * @version:
 * @Author: zhaoxiang
 * @Date: 2020-02-28 15:37:26
 * @LastEditors: zhaoxiang
 * @LastEditTime: 2020-02-28 15:40:48
 */

import Router from 'vue-router';
import groupRouter from './grouprouter';
import platformRouter from './platformrouter';
// import hotelRouter from './hotelrouter';

const RouterView = {
  template: '<div><router-view></router-view></div>',
};

const routes = [
  {
    path: '/crs-config',
    component: RouterView,
    children: [
      ...groupRouter,
      ...platformRouter,
    ],
  },
];

export default new Router({
  mode: 'history',
  routes,
});
