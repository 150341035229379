export default [
  {
    // 网关账号
    path: 'gatewayAccount',
    name: 'gatewayAccount',
    meta: {
      permissionCode: 'cp-gatewayConfigManagement',
      routerType: 1,
    },
    component: () => import('../views/group-management/gateway-config/gateway-account/index.vue'),
  },
  {
    // 网关-url管理
    path: 'URLManagement',
    name: 'URLManagement',
    meta: {
      permissionCode: 'cp-gatewayConfigManagement',
      routerType: 1,
    },
    component: () => import('../views/group-management/gateway-config/url-config/index.vue'),
  },
  {
    // 网关-账号url配置
    path: 'accountURLConfig',
    name: 'accountURLConfig',
    meta: {
      permissionCode: 'cp-gatewayConfigManagement',
      routerType: 1,
    },
    component: () => import('../views/group-management/gateway-config/account-url-config/index.vue'),
  },
  {
    // 网关-Header配置
    path: 'headerConfig',
    name: 'headerConfig',
    meta: {
      permissionCode: 'cp-gatewayConfigManagement',
      routerType: 1,
    },
    component: () => import('../views/group-management/gateway-config/header-config/index.vue'),
  },
  {
    // 数据源配置
    path: 'dataSourceConfig',
    name: 'dataSourceConfig',
    meta: {
      permissionCode: 'cp-fire-eye-second-version',
      routerType: 1,
    },
    component: () => import('../views/group-management/fire-eye-seconde-version/data-source-config/index.vue'),
  },
  {
    // 基础数据配置
    path: 'basicDataConfig',
    name: 'basicDataConfig',
    meta: {
      permissionCode: 'cp-fire-eye-second-version',
      routerType: 1,
    },
    component: () => import('../views/group-management/fire-eye-seconde-version/basic-data-config/index.vue'),
  },
  {
    // 指标数据配置
    path: 'quotaDataConfig',
    name: 'quotaDataConfig',
    meta: {
      permissionCode: 'cp-fire-eye-second-version',
      routerType: 1,
    },
    component: () => import('../views/group-management/fire-eye-seconde-version/quota-data-config/index.vue'),
  },
];
